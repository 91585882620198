/* MainFunctionHallBooking.admin .css */

.admin .main-function-hall-booking {
    background-color: #f5f5f5;
    padding: 2rem;
    border-radius: 10px;
}

.admin .main-function-hall-booking .admin .form-group label {
    /* font-weight: bold; */
    color: #333;
}

.admin .main-function-hall-booking .admin .form-group input, .admin .form-group select {
    border-radius: 5px;
}

.admin .main-function-hall-booking .admin .btn-primary {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
}

.admin .main-function-hall-booking .admin .btn-primary:hover {
    background-color: #0056b3;
}

.admin .main-function-hall-booking .admin .text-danger {
    font-size: 0.admin .875em;
}

.admin .main-function-hall-booking h1.admin .fs-1 {
    color: #333;
    margin-bottom: 20px;
    font-size: 2.admin .5rem;
    font-weight: bold;
}

.admin .main-function-hall-booking .admin .card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.admin .1);
}

.admin .main-function-hall-booking .admin .dropdown-toggle {
    width: 100%;
    text-align: left;
    
}


.admin .main-function-hall-booking .admin .dropdown-menu {
    width: 100%;
}
.admin .confirm-service img{
    width: 100px;
}