/* MainFunctionHallBooking.css */

.main-function-hall-booking {
    background-color: #f5f5f5;
    padding: 2rem;
    border-radius: 10px;
}

.main-function-hall-booking .form-group label {
    font-weight: bold;
    color: #333;
}

.main-function-hall-booking .form-group input, .form-group select {
    border-radius: 5px;
}

.main-function-hall-booking .btn-primary {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
}

.main-function-hall-booking .btn-primary:hover {
    background-color: #0056b3;
}

.main-function-hall-booking .text-danger {
    font-size: 0.875em;
}

.main-function-hall-booking h1.fs-1 {
    color: #333;
    margin-bottom: 20px;
    font-size: 2.5rem;
    font-weight: bold;
}

.main-function-hall-booking .card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.main-function-hall-booking .dropdown-toggle {
    width: 100%;
    text-align: left;
    background-color: #fff;
    color: #333;
}

.main-function-hall-booking .dropdown-menu {
    width: 100%;
}
.confirm-service img{
    width: 100px;
}
