.all-services img{
    height: 150px !important;
    object-fit: cover;
}
.sporti-banner{
    max-height: 70vh;
    object-fit: cover;
    object-position: center top;
}
.service-card{
    position: relative;
    box-shadow: none;
    height: fit-content !important;
    overflow: hidden;
}
.service-info{
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
    cursor: pointer;
    background: #00000079;
    padding: 3px;
}
.all-services{
    position: sticky;
    top: 0px;
}



