@import "~react-image-gallery/styles/css/image-gallery.css";
.popup-image{
    position: relative;
}
.popup-image .next{
    position: absolute;
    right: 0px;
    border: none;
    padding: 10px;
    top: 50%;
    transform: translateY(-50%);
}
.prev{
    position: absolute;
    border: none;
    padding: 10px;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
}
.prev, .next{
    background-color: rgba(240, 248, 255, 0.302);
    color: #fff;
}
.gallery-card img{
    min-height: 200px;
    background-color: #ccc;
}